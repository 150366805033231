import React, { useEffect, useState } from "react";
import { supabase } from "../supabaseClient";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../styles/pages/Home.css";

const klaviyoProxyUrl = `${process.env.REACT_APP_SUPABASE_URL}/functions/v1/klaviyo-proxy`;
const HomeV2 = ({ userInfo }) => {
  const navigate = useNavigate();
  const [adminStats, setAdminStats] = useState(null);
  const [statsLoading, setStatsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [result, setResult] = useState([]);
  const [properties, setProperties] = useState([]);

  // Filter and sort properties
  const filteredProperties = result.filter((property) =>
    property.key.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Get current page items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredProperties.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(result.length / itemsPerPage);

  useEffect(() => {
    getData();
    if (userInfo?.is_admin) {
      fetchAdminStats();
    }
  }, []);

  const getData = async () => {
    const { data: dataProperties } = await supabase.rpc("get_klaviyo_profile");
    console.log('dataProperties', dataProperties)
    const properties = dataProperties.reduce((result, item) => {
      const indexProperty = result.findIndex((e) => e.key === item.key);
      if (indexProperty !== -1) {
        result[indexProperty].count += item.count;
      } else {
        result = [
          ...result,
          {
            key: item.key,
            count: item.count,
            isCustom: !item.key.startsWith("$"),
          },
        ];
      }
      return result;
    }, []);

    setResult(properties.sort((a, b) => b.isCustom - a.isCustom));
    setProperties(dataProperties);
  };
  const handleViewProperty = (property) => {
    const viewProperty = {
      ...property,
      profiles: properties.filter((e) => e.key === property.key),
    };
    navigate("/property-breakdown-v2", { state: { property: viewProperty } });
  };

  const fetchAdminStats = async () => {
    setStatsLoading(true);
    try {
      // Get total user count
      const { data: users, error: usersError } = await supabase
        .from("users")
        .select("id, klaviyo_api_key");

      if (usersError) throw usersError;

      // Get all users' Klaviyo data
      const allPropertiesSet = new Set();
      const allValuesSet = new Set();
      let totalProfiles = 0;

      for (const user of users) {
        if (!user.klaviyo_api_key) continue;

        try {
          const {
            data: { session },
          } = await supabase.auth.getSession();
          if (!session) throw new Error("No session found");

          const response = await axios.post(
            klaviyoProxyUrl,
            {
              klaviyoApiKey: user.klaviyo_api_key,
              endpoint: "/api/v2/people",
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${session.access_token}`,
              },
            }
          );

          if (response.data && response.data.data) {
            totalProfiles += response.data.data.length;

            response.data.data.forEach((profile) => {
              const attributes = profile.attributes;
              if (attributes && attributes.properties) {
                Object.entries(attributes.properties).forEach(
                  ([key, value]) => {
                    allPropertiesSet.add(key);
                    allValuesSet.add(
                      typeof value === "object"
                        ? JSON.stringify(value)
                        : String(value)
                    );
                  }
                );
              }
            });
          }
        } catch (error) {
          console.error(`Error fetching data for user ${user.id}:`, error);
        }
      }

      setAdminStats({
        totalUsers: users.length,
        totalProperties: allPropertiesSet.size,
        totalValues: allValuesSet.size,
        totalProfiles: totalProfiles,
      });
    } catch (error) {
      console.error("Error fetching admin stats:", error);
    } finally {
      setStatsLoading(false);
    }
  };

  return (
    <div className="home-container">
      <h1 className="welcome-message">
        Welcome, {userInfo?.user_name?.split(" ")[0] || "User"}
      </h1>

      <div className="data-container">
        {userInfo?.is_admin && (
          <div className="stats-section">
            <h2>Overall Statistics</h2>
            <div className="stats-grid">
              <div className="stat-box">
                <h4>Users</h4>
                {statsLoading ? (
                  <div className="loading-spinner"></div>
                ) : (
                  <p>{adminStats?.totalUsers || 0}</p>
                )}
              </div>
              <div className="stat-box">
                <h4>Properties</h4>
                {statsLoading ? (
                  <div className="loading-spinner"></div>
                ) : (
                  <p>{adminStats?.totalProperties || 0}</p>
                )}
              </div>
              <div className="stat-box">
                <h4>Values</h4>
                {statsLoading ? (
                  <div className="loading-spinner"></div>
                ) : (
                  <p>{adminStats?.totalValues || 0}</p>
                )}
              </div>
              <div className="stat-box">
                <h4>Profiles</h4>
                {statsLoading ? (
                  <div className="loading-spinner"></div>
                ) : (
                  <p>{adminStats?.totalProfiles || 0}</p>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="user-data">
          <h2>Your Properties</h2>
          <div className="table-container">
            <div className="search-container">
              <input
                type="text"
                placeholder="Search properties..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input"
              />
            </div>
            <table className="data-table">
              <thead>
                <tr>
                  <th>Property</th>
                  <th>Count</th>
                  <th>Type</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((property, index) => (
                  <tr
                    key={index}
                    className={
                      property.isCustom ? "custom-property" : "global-property"
                    }
                  >
                    <td>{property.key}</td>
                    <td>{property.count}</td>
                    <td>{property.isCustom ? 'Custom' : 'Global'}</td>
                    <td>
                      <button
                        className="view-button"
                        onClick={() => handleViewProperty(property)}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {totalPages > 1 && (
              <div className="pagination">
                <button
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="pagination-button"
                >
                  Previous
                </button>
                <span>
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="pagination-button"
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {/*{loading && (*/}
      {/*  <div className="loading-container">*/}
      {/*    <div className="progress-bar">*/}
      {/*      <div*/}
      {/*        className="progress-bar-fill"*/}
      {/*        style={{ width: `${loadingProgress}%` }}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <p className="loading-message">{loadingMessage}</p>*/}
      {/*    {loadingProgress < 100 && <p>Loading profile data...</p>}*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
};

export default HomeV2;
